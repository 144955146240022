import { css } from '@emotion/react'
import { Avatar, Button, ContentContainerBackoffice, spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import type { ReactElement } from 'react'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'

export default function PlatformInvoicingPage(): ReactElement {
  const navigateToNegativeInvoicesList = useNavigateTo({ to: '/bak/invoicing/negative-invoices-list' })

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header title="Facturation" />

      <div
        css={css`
          display: flex;
          gap: ${spacing[50]};
        `}
      >
        <Button
          variant="secondary"
          avatar={<Avatar icon="arrow-right-regular" />}
          onClick={navigateToNegativeInvoicesList}
        >
          Liste des factures négatives
        </Button>
      </div>
    </ContentContainerBackoffice>
  )
}
