import { createFileRoute } from '@tanstack/react-router'

import PlatformInvoicingPage from '../../../components/pages/backoffice/platform/platform-invoicing-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'

export const Route = createFileRoute('/bak/invoicing/')({
  component: () => (
    <PermissionChecker requiredPermissions={['invoices.read']}>
      <PlatformInvoicingPage />
    </PermissionChecker>
  ),
})
